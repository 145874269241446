import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Alert } from "react-bootstrap";

import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { useUserAuth } from "../context/UserAuthContext";
import logo from './assets/logo22.png'
export default function Login() {
 
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { logIn, googleSignIn } = useUserAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await logIn(email, password);
      navigate("/home");
    } catch (err) {

      var m=err.message
var mm=m.slice(22,-2)

      setError(mm);
    }
  };

  // const handleGoogleSignIn = async (e) => {
  //   e.preventDefault();
  //   try {
  //     await googleSignIn();
  //     navigate("/home");
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // }

  return (
    < div className='main flex'>
    <div id='loginPage' className='flex'>
 <div className='imgContainer flex'>
 
  <img id='logoLP'src={logo} alt='logo'/>

 </div>
 <div className='loginForm flex'>
 <h1 className="Heading">LOGIN</h1>
 {error && <h3 className="alert" variant="danger">{error}</h3>}
  <h2>Welcome Back! Login here to continue</h2>
  <div id='inputDiv'>

 <div className='inputDivS'>
  <label>Email Id</label> <br></br>
            <input className="monoInput"
              type="email"
              placeholder="Email address"
              onChange={(e) => setEmail(e.target.value)}
            />
             </div>
             <div className='inputDivS'>
             <label>Password</label> <br></br>
            <input className="monoInput"
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
        </div>
        </div>
            <button variant="primary"  onClick={handleSubmit} className='otpButton '>Log In</button>

     
      

  
 <h3>Don't have an account? <Link to='/Signup'>Create Account</Link></h3>
 </div>


    </div>
 
    </div>
  )
}
