import React from 'react'
import { Link } from 'react-router-dom'
import logo from './assets/logo22.png'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { Form, Alert,Button } from "react-bootstrap";


import { useState } from "react";
import {useNavigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";


export default function Signup() {

  
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [error, setError] = useState("");
    const [password, setPassword] = useState("");
    const { signUp } = useUserAuth();
    let navigate = useNavigate();
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setError("");
      try {
        await signUp(email, password, name);
        navigate("/");
      } catch (err) {
var m=err.message
var mm=m.slice(22,-2)
        
        setError(mm);
      }
    };







  return (







    < div className='main flex'>
    <div id='signupPage' className='flex'>
 <div className='imgContainer flex'>
 
 
  <img id='logoSP' src={logo} alt='logo'/>

 </div>
 <div className='signupForm flex'>
 <h1 className='Heading2'>SIGNUP</h1>
 {error && <h3 className="alert" >{error}</h3>}

  <h2>Hey welcome to ENGRAVE IT! fill the form to join</h2>
  
  <div className='inputDivS'>
  <label>Name</label> <br></br> 
  <input className="monoInput"   type="text"
              placeholder=" name"
              onChange={(e) => setName(e.target.value)} ></input>
  </div>
  <div className='inputDivS'>
  <label>Email</label> <br></br> 
  <input className="monoInput"   type="email"
              placeholder="Email address"
              onChange={(e) => setEmail(e.target.value)} ></input>
  </div>
  <div className='inputDivS'>
  <label>Password</label> <br></br> 
  <input className="monoInput" type='password' placeholder="Enter your password"   onChange={(e) => setPassword(e.target.value)}></input>
  </div>
 
<button className='otpButton' onClick={handleSubmit} >Sign Up</button>
 <h3>Alredy have an account? <Link to='/'>Log In</Link></h3>
 </div>


    </div>
 
    </div>
  )
}
