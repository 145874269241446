import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAESYYwt3QHgfS-mmncqUCMw-BNjKc1ddQ",
  authDomain: "engraveit-44635.firebaseapp.com",
  projectId: "engraveit-44635",
  storageBucket: "engraveit-44635.appspot.com",
  messagingSenderId: "951790431561",
  appId: "1:951790431561:web:eb874a7f876af44fb07e98",
  measurementId: "G-NSXLHVHD7C"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;
